import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let UserLogin = class UserLogin extends Vue {
};
UserLogin = __decorate([
    Component({
        name: 'UserLogin',
        components: {
            LoginFormUser: () => import('@/components/login/index.vue'),
        },
    })
], UserLogin);
export default UserLogin;
