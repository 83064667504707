import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserLogin from './user-login.vue';
import ManagerLogin from './manager-login.vue';
const LOGIN_COMPONENTS = {
    user: UserLogin,
    operation: ManagerLogin,
};
let Login = class Login extends Vue {
    get LoginComponent() {
        return LOGIN_COMPONENTS[this.$route.params.type];
    }
};
Login = __decorate([
    Component({
        name: 'Login',
    })
], Login);
export default Login;
